import { logout } from "../api/auth";
import { useUser } from "../context/UserManager";
import { Link, useParams, useLocation } from "react-router-dom";
import { FaHome, FaList, FaKey, FaBook, FaSignOutAlt, FaUser, FaChevronDown } from "react-icons/fa";
import { useState, useRef, useEffect } from "react";

function NavBar() {
    const { user } = useUser();
    const { orgId } = useParams();
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const logoutHandler = async () => {
        if (!window.confirm("Are you sure you want to logout?")) return
        await logout();
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const getLinkClass = (path: string) => {
        const isActive = location.pathname === path;
        return `relative transition-all duration-200 py-3 flex items-center hover:text-gray-900 group ${isActive ? 'text-black' : 'text-gray-600'
            }`;
    }

    return (
        <div className="backdrop-blur-sm bg-white sticky top-0 z-50 border-b border-gray-200 w-screen">
            <div className="w-full px-8">
                <div className="flex flex-row justify-between h-16">
                    <div className="flex flex-row gap-8 items-center">
                        <div className="font-bold text-xl text-black">Firebook</div>

                        <div className="flex gap-6">
                            <Link
                                to={`/o/${orgId}`}
                                className={getLinkClass(`/o/${orgId}`)}
                            >
                                <FaHome className="mr-2" />
                                <span>Overview</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>

                            <Link
                                to={`/o/${orgId}/entries`}
                                className={getLinkClass(`/o/${orgId}/entries`)}
                            >
                                <FaList className="mr-2" />
                                <span>Entry feed</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}/entries` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>

                            <Link
                                to={`/o/${orgId}/api-keys`}
                                className={getLinkClass(`/o/${orgId}/api-keys`)}
                            >
                                <FaKey className="mr-2" />
                                <span>API Keys</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}/api-keys` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>

                            <Link
                                to={`/o/${orgId}/docs`}
                                className={getLinkClass(`/o/${orgId}/docs`)}
                            >
                                <FaBook className="mr-2" />
                                <span>Docs</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}/docs` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="relative" ref={dropdownRef}>
                            <div
                                className="flex items-center px-4 py-2 rounded-full hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                                <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center text-gray-900 mr-2">
                                    <FaUser className="w-4 h-4" />
                                </div>
                                <span className="text-gray-900">{user?.firstName} {user?.lastName}</span>
                                <FaChevronDown className={`ml-2 text-gray-600 transform transition-transform duration-200 ${dropdownOpen ? 'rotate-180' : ''}`} />
                            </div>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-10 border border-gray-200 transform transition-all duration-200 ease-out">
                                    <div
                                        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer flex items-center group"
                                        onClick={logoutHandler}
                                    >
                                        <FaSignOutAlt className="mr-2 text-gray-400 group-hover:text-black transition-colors duration-200" />
                                        <span className="group-hover:text-black transition-colors duration-200">Logout</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;