import { getPostFetcher, PostKey } from "../api/api"
import useSWR from "swr"

export function useStatement(orgId: string, fromDate: Date, toDate: Date) {
    const { data } = useSWR<{
        income: number,
        expenses: number,
        incomeByCurrency: { currency: string, sum: number, sumUsd: number }[],
        expensesByCurrency: { currency: string, sum: number, sumUsd: number }[],
    }, any, PostKey>({
        url: `/organization/${orgId}/statement`,
        body: {
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
        }
    }, getPostFetcher)

    return {
        income: data?.income ?? 0,
        expenses: data?.expenses ?? 0,
        incomeByCurrency: data?.incomeByCurrency ?? [],
        expensesByCurrency: data?.expensesByCurrency ?? [],
        isLoading: !data,
    }
}

export function useDailyStatement(orgId: string, fromDate: Date, toDate: Date) {
    const { data } = useSWR<{
        incomePerDay: { date: string, sum: number, sumUsd: number }[],
        expensesPerDay: { date: string, sum: number, sumUsd: number }[],
        incomeByCurrencyPerDay: { currency: string, date: string, sum: number, sumUsd: number }[],
        expensesByCurrencyPerDay: { currency: string, date: string, sum: number, sumUsd: number }[],
    }, any, PostKey>({
        url: `/organization/${orgId}/statement/daily`,
        body: {
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
        }
    }, getPostFetcher)

    return {
        incomePerDay: data?.incomePerDay ?? [],
        expensesPerDay: data?.expensesPerDay ?? [],
        incomeByCurrencyPerDay: data?.incomeByCurrencyPerDay ?? [],
        expensesByCurrencyPerDay: data?.expensesByCurrencyPerDay ?? [],
        isLoading: !data,
    }
}