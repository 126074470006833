import { ApiError } from "../api.types";

export const apiEndpoint = process.env.API_ENDPOINT ?? 'https://api.firebook.io';

export const getUserToken = (): string | null => {
    return window.localStorage.getItem("user_token");
};

export const getHeaders = () => {
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json",
    };

    let userToken = getUserToken();

    if (userToken) {
        headers["Authorization"] = "Bearer " + userToken;
    }

    return headers;
};

export const getFetcher = (url: string) => {
    return fetch(`${apiEndpoint}${url}`, {
        headers: getHeaders(),
        cache: "no-store",
    }).then((r) => r.json());
};

export type PostKey = {
    url: string,
    body: any,
}

export const getPostFetcher = (params: PostKey) => {
    return fetch(`${apiEndpoint}${params.url}`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(params.body),
        cache: "no-store",
    }).then((r) => r.json());
};

export const post = <T>(url: string, body: any): Promise<T & ApiError> => {
    return fetch(`${apiEndpoint}${url}`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
    }).then((r) => r.json());
};

export const put = <T>(url: string, body: any): Promise<T & ApiError> => {
    return fetch(`${apiEndpoint}${url}`, {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify(body),
    }).then((r) => r.json());
};

export const del = (url: string, body: any = {}): Promise<any> => {
    return fetch(`${apiEndpoint}${url}`, {
        method: "DELETE",
        headers: getHeaders(),
        body: JSON.stringify(body),
    }).then((r) => r.json());
};

export const get = <T>(url: string): Promise<T & ApiError> => {
    return fetch(`${apiEndpoint}${url}`, {
        method: "GET",
        headers: getHeaders(),
    }).then((r) => r.json());
};