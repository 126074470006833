import { useApiKeys, useCreateApiKey, useDeleteApiKey } from "../hooks/useApiKeys"
import { useParams } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"

function ApiKeys() {
    const { orgId } = useParams()
    const { apiKeys } = useApiKeys(orgId as string)
    const createKey = useCreateApiKey(orgId as string)
    const deleteKey = useDeleteApiKey(orgId as string)

    const createKeyHandler = async () => {
        const name = prompt("Enter a name for the key")
        if (!name) return

        await createKey(name)
    }

    const deleteKeyHandler = async (keyId: string, keyName: string) => {
        if (!window.confirm(`Are you sure you want to delete the key "${keyName}"?`)) {
            return
        }
        await deleteKey(keyId)
    }

    return <div className="container mx-auto py-10">
        <div className="text-2xl mb-4">API Keys</div>

        <table className="w-full border-collapse text-sm border rounded">
            <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                    <th className="text-left p-4 font-medium">Name</th>
                    <th className="text-left p-4 font-medium">Key</th>
                    <th className="text-left p-4 font-medium">Created</th>
                    <th className="text-left p-4 font-medium">Actions</th>
                </tr>
            </thead>
            <tbody>
                {apiKeys?.map(key => (
                    <tr key={key.id} className="border-b border-gray-200 hover:bg-gray-50">
                        <td className="p-4">{key.name}</td>
                        <td className="p-4 font-mono text-sm">{key.key}</td>
                        <td className="p-4 text-gray-600">{formatDistanceToNow(new Date(key.createdAt))} ago</td>
                        <td className="p-4">
                            <button
                                onClick={() => deleteKeyHandler(key.id, key.name)}
                                className="text-red-600 hover:text-red-800"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="flex justify-end mt-4">
            <button
                onClick={createKeyHandler}
                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
            >
                Create new key
            </button>
        </div>
    </div>
}

export default ApiKeys