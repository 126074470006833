import useSWR, { mutate } from "swr";
import { del, getFetcher, getPostFetcher, PostKey } from "../api/api";
import { ApiEntry } from "../api.types";

export function useEntries(orgId: string, filters?: {
    tags?: string[],
}) {
    let query: Record<string, any> = {}

    if (filters?.tags) {
        query.tags = filters.tags
    }

    const { data, isLoading } = useSWR<{
        entries: ApiEntry[],
        count: number,
        statement: {
            income: number,
            expenses: number,
            expensesByCurrency: { currency: string, sum: number, sumUsd: number }[],
            incomeByCurrency: { currency: string, sum: number, sumUsd: number }[],
        }
    }, any, PostKey>({ body: query, url: `/organization/${orgId}/entries` }, getPostFetcher, {

    })

    return {
        entries: data?.entries ?? [],
        count: data?.count ?? 0,
        statement: data?.statement ?? {
            income: 0,
            expenses: 0,
            expensesByCurrency: [],
            incomeByCurrency: [],
        },
        isLoading,
    }
}

export function useEntry(orgId: string, entryId: string) {
    const { data } = useSWR<ApiEntry>(`/organization/${orgId}/entries/${entryId}`, getFetcher)
    return {
        entry: data,
    }
}

export function useEntryValue(orgId: string, entryId: string) {
    const { entry } = useEntry(orgId, entryId)

    return entry?.valueAtDate
}

export function useDeleteEntry(orgId: string) {
    return async (entryId: string) => {
        await del(`/organization/${orgId}/entries/${entryId}`)

        await mutate(`/organization/${orgId}/entries`)
    }
}