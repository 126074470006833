import { useEntry, useDeleteEntry } from "../hooks/useEntries"
import { useSearchParams } from "react-router-dom"
import { IoMdClose } from "react-icons/io"

interface EntryDrawerProps {
    orgId: string
    entryId: string
}

function EntryDrawer({ orgId, entryId }: EntryDrawerProps) {
    const { entry } = useEntry(orgId, entryId)
    const deleteEntry = useDeleteEntry(orgId)
    const [, setSearchParams] = useSearchParams()

    const handleDelete = async () => {
        if (!entry) return
        if (!window.confirm('Are you sure you want to delete this entry?')) return

        await deleteEntry(entry.id)
        setSearchParams({})
    }

    if (!entry) return null

    return (
        <>
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
                onClick={() => setSearchParams({})}
            />

            {/* Drawer */}
            <div
                style={{ width: 600 }}
                className="fixed inset-y-0 right-0 w-96 bg-white shadow-lg p-6 transform transition-transform duration-300 translate-x-0"
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Entry Details</h2>
                    <button
                        onClick={() => setSearchParams({})}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <IoMdClose className="text-2xl" />
                    </button>
                </div>

                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Date</label>
                        <div>{new Date(entry.dateOfEntry).toLocaleDateString()}</div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-600">Type</label>
                        <div className={entry.type === 'income' ? 'text-green-600' : 'text-red-600'}>
                            {entry.type.charAt(0).toUpperCase() + entry.type.slice(1)}
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-600">Amount</label>
                        <div>{entry.amount} {entry.currency}</div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-600">Value in USD</label>
                        <div>{entry.valueAtDate?.toLocaleString()} USD</div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-600">Tags</label>
                        <div className="flex flex-wrap gap-1">
                            {entry.tags.map((tag, i) => (
                                <span key={i} className="bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-600">Metadata</label>
                        <table className="w-full text-sm">
                            <tbody>
                                {entry.metadata && Object.entries(entry.metadata).map(([key, value]) => (
                                    <tr key={key} className="border-b border-gray-100">
                                        <td className="py-2 font-medium text-gray-600">{key}</td>
                                        <td className="py-2 text-gray-800">{value as string}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="pt-4">
                        <button
                            onClick={handleDelete}
                            className="w-full bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded"
                        >
                            Delete Entry
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EntryDrawer