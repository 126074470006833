import useSWR, { mutate } from "swr"
import { del, getFetcher, post } from "../api/api"
import { ApiKey } from "../api.types"

export function useApiKeys(orgId: string) {
    const { data } = useSWR<ApiKey[]>(`/organization/${orgId}/api_keys`, getFetcher)

    return {
        apiKeys: data,
    }
}

export function useCreateApiKey(orgId: string) {
    return async (name: string) => {
        const res = await post(`/organization/${orgId}/api_keys`, { name, roles: ['*'] })

        await mutate(`/organization/${orgId}/api_keys`, null, true)
    }
}

export function useDeleteApiKey(orgId: string) {
    return async (keyId: string) => {
        await del(`/organization/${orgId}/api_keys/${keyId}`)
        await mutate(`/organization/${orgId}/api_keys`, null, true)
    }
}