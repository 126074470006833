import React from 'react';
import { ApiOrg, ApiUser } from './api.types';
import { getFetcher } from './api/api';
import useSWR from 'swr';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Loader from './components/Loader';
import Authenticated from './Authenticated';
import UserManager from './context/UserManager';
import Register from './containers/Register';
import Login from './containers/Login';
import NewOrgModal from './containers/NewOrgPage';

const App = () => {
  const { data, error } = useSWR<ApiUser & {
    organizations: ApiOrg[]
  } | any>("/user", getFetcher);

  if (!data && !error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (error || data?.message == "Unauthorized" || data?.error) {
    return (
      <Router>
        <Routes>
          <Route path="/signup" element={<Register />} />
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  }

  if (data?.organizations?.length === 0) {
    return (
      <UserManager user={data}>
        <NewOrgModal />
      </UserManager>
    )
  }

  return (
    <UserManager user={data}>
      <Router>
        <Routes>
          <Route path="/o/:orgId*" element={<Authenticated />} />
          <Route path="*" element={<Navigate to={`/o/${data.organizations[0].id}`} />} />
        </Routes>
      </Router>
    </UserManager>
  );
};

export default App;
