import React from 'react'
import { ApiUser } from '../api.types'

export type UserContextProps = {
    user: ApiUser;
}

// @ts-ignore
export const UserContext = React.createContext<UserContextProps>({})

export type UserManagerProps = {
    user: ApiUser;
    children: React.ReactNode;
}

const UserManager: React.FC<UserManagerProps> = (props) => {
    return (
        <UserContext.Provider value={{
            user: props.user as any
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export const useUser = () => {
    return React.useContext(UserContext)
}

export default UserManager