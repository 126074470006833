import { useState, FormEvent } from 'react';
import { useCreateOrg } from '../hooks/useOrgs';

function NewOrgPage() {
    const [orgName, setOrgName] = useState('');
    const createOrg = useCreateOrg();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            await createOrg(orgName);
        } catch (err: any) {
            alert(err.message);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-5">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold mb-6 text-center">Create New Organization</h2>

                    <div className="mb-6">
                        <label htmlFor="orgName" className="block mb-2 text-sm font-medium text-gray-700">
                            Organization Name:
                        </label>
                        <input
                            type="text"
                            id="orgName"
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter organization name"
                        />
                    </div>

                    <div className="flex gap-4">
                        <button
                            type="submit"
                            className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewOrgPage;