import useSWR, { mutate } from "swr"
import { ApiOrg } from "../api.types"
import { getFetcher, post } from "../api/api"

export function useOrgs() {
    const { data } = useSWR<ApiOrg[]>("/organizations", getFetcher)

    return {
        orgs: data,
    }
}

export function useCreateOrg() {
    return async (name: string) => {
        const res = await post(`/organizations`, { name })

        await mutate("/organizations", null, true)
        await mutate("/user", null, true)
    }
}