import { mutate } from "swr";
import { get, post } from "./api";

export function setToken(token: string) {
    window.localStorage.setItem("user_token", token);

    mutate("/me", null, true);
}

export async function logout() {
    await post("/logout", {});

    window.localStorage.removeItem("user_token");

    mutate("/user", null);
}

export async function login(
    email: string,
    password: string,
    longSession?: boolean
): Promise<boolean> {
    const { token, message } = await post<{ token: string }>(
        "/auth/login",
        {
            email,
            password,
            longSession,
        }
    );

    if (message) {
        throw new Error(message);
    }

    window.localStorage.setItem("user_token", token);

    await mutate("/user", null, true);

    return true;
}

export async function loginWithGoogleCode(code: string): Promise<boolean> {
    const { token, message } = await get<{ token: string }>(
        "/auth/callback/google?code=" + code
    );

    if (message) {
        throw new Error(message);
    }

    window.localStorage.setItem("user_token", token);

    await mutate("/me", null, true);

    return true;
}

export async function loginWithToken(token: string) {
    window.localStorage.setItem("user_token", token);

    await mutate("/user", null, true);

    return true;
}

export async function register(
    firstName: string,
    lastName: string,
    email: string,
    password: string
): Promise<boolean> {
    const { token, message } = await post<{ token: string }>(
        "/auth/register",
        {
            firstName,
            lastName,
            email,
            password,
        }
    );

    if (message) {
        throw new Error(message);
    }

    window.localStorage.setItem("user_token", token);

    await mutate("/user", null, true);
    return true;
}