import { useState, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useDailyStatement, useStatement } from "../hooks/useStatement"
import { startOfMonth } from "date-fns"
import { FaChartPie, FaArrowUp, FaCoins, FaArrowDown, FaCalculator } from "react-icons/fa"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

function Home() {
    const { orgId } = useParams()
    const [period, setPeriod] = useState("current-month")

    const periods: Record<string, { from: Date, to: Date }> = useMemo(() => ({
        "current-month": { from: startOfMonth(new Date()), to: new Date() },
        "last-30": { from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), to: new Date() },
        "last-90": { from: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), to: new Date() },
    }), [])

    const { income, expenses, isLoading: isLoadingStatement, incomeByCurrency, expensesByCurrency } = useStatement(orgId as string, periods[period].from, periods[period].to)
    const { incomePerDay, expensesPerDay, isLoading: isLoadingDailyStatement, incomeByCurrencyPerDay, expensesByCurrencyPerDay } = useDailyStatement(orgId as string, periods[period].from, periods[period].to)

    const chartData = useMemo(() => {
        return incomePerDay.map((income, index) => ({
            date: new Date(income.date),
            income: income.sum,
            expenses: expensesPerDay[index]?.sum || 0,
            net: income.sum - (expensesPerDay[index]?.sum || 0)
        }))
    }, [incomePerDay, expensesPerDay])

    return <div className="p-4">
        <select
            value={period}
            onChange={e => setPeriod(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
        >
            <option value="current-month">Current Month</option>
            <option value="last-30">Last 30 Days</option>
            <option value="last-90">Last 3 Months</option>
        </select>

        <div className="flex flex-row gap-4">
            <div className="p-4 bg-gray-50 border flex-1 border-gray-300 rounded-lg shadow-sm">
                <h2 className="text-lg font-semibold mb-4 flex items-center text-gray-800">
                    <FaChartPie className="mr-2 text-blue-500" />
                    Daily Income & Expenses
                </h2>

                {!isLoadingDailyStatement && (
                    <div style={{ height: 300 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(date) => date.toLocaleDateString()}
                                />
                                <YAxis
                                    tickFormatter={(value) => `$${value.toLocaleString()}`}
                                />
                                <Tooltip
                                    labelFormatter={(date) => date.toLocaleDateString()}
                                    formatter={(value) => [`$${Number(value).toLocaleString()}`]}
                                />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="income"
                                    stroke="#10B981"
                                    name="Income"
                                    strokeWidth={2}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="expenses"
                                    stroke="#EF4444"
                                    name="Expenses"
                                    strokeWidth={2}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="net"
                                    stroke="#6366F1"
                                    name="Net Revenue"
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                )}
            </div>
            <div style={{ width: 500 }} className="p-4 bg-gray-50 border border-gray-300 rounded-lg shadow-sm">
                <h2 className="text-lg font-semibold mb-4 flex items-center text-gray-800">
                    <FaChartPie className="mr-2 text-blue-500" />
                    Statement Breakdown
                </h2>

                <div className="mb-6">
                    <h3 className="font-medium mb-2 flex items-center text-green-700">
                        <FaArrowUp className="mr-2" />
                        Income
                    </h3>
                    {incomeByCurrency.map(income => (
                        <div key={income.currency} className="flex justify-between text-sm text-gray-600">
                            <span className="flex items-center">
                                <FaCoins className="mr-2 text-yellow-500" />
                                {income.sum} {income.currency.toUpperCase()}
                            </span>
                            <span>${Number(income.sumUsd).toLocaleString()}</span>
                        </div>
                    ))}
                    <div className="flex justify-between font-medium mt-2 pt-2 border-t text-green-600">
                        <span>Total Income</span>
                        <span>${Number(income).toLocaleString()}</span>
                    </div>
                </div>

                <div className="mb-6">
                    <h3 className="font-medium mb-2 flex items-center text-red-700">
                        <FaArrowDown className="mr-2" />
                        Expenses
                    </h3>
                    {expensesByCurrency.map(expense => (
                        <div key={expense.currency} className="flex justify-between text-sm text-gray-600">
                            <span className="flex items-center">
                                <FaCoins className="mr-2 text-yellow-500" />
                                {expense.sum} {expense.currency.toUpperCase()}
                            </span>
                            <span>${Number(expense.sumUsd).toLocaleString()}</span>
                        </div>
                    ))}
                    <div className="flex justify-between font-medium mt-2 pt-2 border-t text-red-600">
                        <span>Total Expenses</span>
                        <span>${Number(expenses).toLocaleString()}</span>
                    </div>
                </div>

                <div className="flex justify-between font-semibold text-lg pt-4 border-t">
                    <span className="flex items-center">
                        <FaCalculator className="mr-2 text-purple-500" />
                        Net Total
                    </span>
                    <span className={income - expenses >= 0 ? "text-green-600" : "text-red-600"}>
                        ${Number(income - expenses).toLocaleString()}
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default Home