import { useApiKeys } from "../hooks/useApiKeys"
import { useParams } from "react-router-dom"

function Docs() {
    const { orgId } = useParams()
    const { apiKeys } = useApiKeys(orgId as string)

    return (
        <div className="h-[calc(100vh-80px)]">
            <div className="text-sm text-gray-500 mb-4">Supported currencies: eth, bnb, usd, sol</div>
            <div className="max-w-3xl mx-auto mt-12">
                <h2 className="text-2xl font-bold mb-4">Logging Income</h2>

                <div className="bg-gray-50 p-6 rounded-lg mb-6">
                    <h3 className="text-lg font-semibold mb-3">Using cURL</h3>
                    <pre className="bg-gray-900 text-gray-100 p-4 rounded-md overflow-x-auto">
                        {`curl -X POST https://api.firebook.io/v1/entries \\
-H "Authorization: Bearer ${apiKeys?.[0].key}" \\
-H "Content-Type: application/json" \\
-d '{
"amount": 100.31,
"currency": "eth",
"type": "income",
"tags": ["test:me"],
"metadata": {
    "customer_id": "123"
}
}'`}
                    </pre>
                </div>
            </div>
            <div className="max-w-3xl mx-auto mt-12">
                <h2 className="text-2xl font-bold mb-4">Logging Expenses</h2>

                <div className="bg-gray-50 p-6 rounded-lg mb-6">
                    <h3 className="text-lg font-semibold mb-3">Using cURL</h3>
                    <pre className="bg-gray-900 text-gray-100 p-4 rounded-md overflow-x-auto">
                        {`curl -X POST https://api.firebook.io/v1/entries \\
  -H "Authorization: Bearer ${apiKeys?.[0].key}" \\
  -H "Content-Type: application/json" \\
  -d '{
    "amount": 100.31,
    "currency": "eth",
    "type": "expense",
    "tags": ["test:me"],
    "metadata": {
        "customer_id": "123"
    }
}'`}
                    </pre>
                </div>
            </div>
        </div>
    )
}

export default Docs